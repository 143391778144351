import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    lang: 'en-US',
    userInfo: {},
    someData: {}
  },
  getters: {
    getToken (state) {
      return state.token
    },
    getUserInfo (state) {
      return state.userInfo
    },
    getLang (state) {
      return state.lang
    }
  },
  mutations: {
    setToken (state, token) {
      this.state.token = token
    },
    setUserInfo (state, userInfo) {
      this.state.userInfo = userInfo
    },
    setLang (state, lang) {
      this.state.lang = lang
    },
    setSomeData(state, data) {
      state.someData = data;
    }
  },
  actions: {
    setLang ({ commit }, lang) {
      commit('setLang', lang)
    },
    updateSomeData({ commit }, data) {
      commit('setSomeData', data);
    }
  }
})
